import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
const Schedule = ({ events = [] }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };

  const hourHeight = 300; // Height for each hour
  const startHour = events.length && events[0].day === "Friday" ? 10 : 10;
  const timeColumnOffset = 50;

  // Total hours from startHour (10 AM) through to 2 AM next day = 16 hours total
  const totalHours = 16;
  const timeSlots = Array.from({ length: totalHours }, (_, i) => {
    const hour = startHour + i;
    const adjustedHour = hour >= 24 ? hour - 24 : hour;
    const displayHour = adjustedHour % 12 === 0 ? 12 : adjustedHour % 12;
    const suffix = adjustedHour >= 12 && adjustedHour < 24 ? "PM" : "AM";
    return `${displayHour}:00 ${suffix}`;
  });

  // Group events by room
  const eventsByRoom = events.reduce((acc, event) => {
    if (!acc[event.room]) {
      acc[event.room] = [];
    }
    acc[event.room].push(event);
    return acc;
  }, {});

  // Sort rooms by location_sort and room_sort
  const sortedRooms = Object.entries(eventsByRoom).sort((a, b) => {
    // eslint-disable-next-line
    const [roomA, eventsA] = a;
    // eslint-disable-next-line
    const [roomB, eventsB] = b;

    const eventA = eventsA[0];
    const eventB = eventsB[0];

    if (eventA.location_sort < eventB.location_sort) return -1;
    if (eventA.location_sort > eventB.location_sort) return 1;

    if (eventA.room_sort < eventB.room_sort) return -1;
    if (eventA.room_sort > eventB.room_sort) return 1;

    return 0;
  });

  // Process and calculate layout for each room
  const processedRooms = sortedRooms.map(([room, roomEvents]) => {
    const sortedEvents = roomEvents.sort(
      (a, b) => new Date(a.full_start) - new Date(b.full_start)
    );

    const clusters = [];
    let currentCluster = [sortedEvents[0]];

    for (let i = 1; i < sortedEvents.length; i++) {
      const prevEvent = currentCluster[currentCluster.length - 1];
      const currentEvent = sortedEvents[i];

      const prevEnd = new Date(prevEvent.full_end);
      const currentStart = new Date(currentEvent.full_start);

      if (prevEnd > currentStart) {
        currentCluster.push(currentEvent);
      } else {
        clusters.push(currentCluster);
        currentCluster = [currentEvent];
      }
    }

    if (currentCluster.length) {
      clusters.push(currentCluster);
    }

    const maxOverlap = Math.max(...clusters.map((c) => c.length), 1);

    const eventsWithLayout = clusters.flatMap((cluster) => {
      const clusterSize = cluster.length;
      return cluster.map((event, index) => ({
        ...event,
        clusterSize,
        clusterPosition: index,
      }));
    });

    return { room, events: eventsWithLayout, maxOverlap };
  });

  return (
    <div className="container-fluid mt-4">
      <div className="d-flex">
        {/* Time Slots Column */}
        <div
          className="flex-shrink-0 time-column"
          style={{
            width: "100px",
            marginTop: `${timeColumnOffset}px`,
          }}
        >
          {timeSlots.map((time) => (
            <div
              key={time}
              className="border-bottom p-2 text-center"
              style={{
                height: `${hourHeight}px`,
                borderColor: "#b0d4c1",
              }}
            >
              {time}
            </div>
          ))}
        </div>

        {/* Scrollable Room Columns */}
        <div className="flex-grow-1 overflow-auto" style={{ whiteSpace: "nowrap" }}>
          <div className="d-flex">
            {processedRooms.map(({ room, events, maxOverlap }) => (
              <div
                key={room}
                className="flex-shrink-0 border-start"
                style={{
                  width: `${200 + (maxOverlap - 1) * 50}px`, // Adjust column width based on maxOverlap
                  borderColor: "#b0d4c1",
                }}
              >
                <div className="text-center fw-bold border-bottom py-2 sticky-header">
                  {room}
                </div>
                <div style={{ position: "relative", height: `${timeSlots.length * hourHeight}px` }}>
                  {events.map((event) => {
                    const start = new Date(event.full_start);
                    const end = new Date(event.full_end);

                    let startHourOfEvent = start.getHours();
                    let endHourOfEvent = end.getHours();

                    if (startHourOfEvent < startHour) {
                      startHourOfEvent += 24;
                    }
                    if (endHourOfEvent < startHour) {
                      endHourOfEvent += 24;
                    }

                    const startOffset =
                      ((startHourOfEvent - startHour) * hourHeight) +
                      (start.getMinutes() * (hourHeight / 60));
                    const duration =
                      (((endHourOfEvent * 60 + end.getMinutes()) - (startHourOfEvent * 60 + start.getMinutes())) / 60) * hourHeight;

                    const eventWidth = 90 / event.clusterSize;
                    const eventLeft = event.clusterPosition * eventWidth;

                    return (
                      <div
                        key={event.id}
                        className={`border rounded word-wrap p-2 position-absolute ${
                          event.paid_event === 1
                            ? "event-paid"
                            : event.age_rating === "18+"
                            ? "event-18plus"
                            : "event-default"
                        }`}
                        style={{
                          top: `${startOffset}px`,
                          height: `${duration}px`,
                          left: `${eventLeft}%`,
                          width: `${eventWidth}%`,
                          borderColor: "#b0d4c1",
                        }}
                        onClick={() => handleEventClick(event)}
                      >
                        <h6 className="fw-bold">{event.name}</h6>
                        <p className="mb-1 small">
                          {start.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} - {end.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
                        </p>
                        <p className="small mb-0">
                          {event.location} - {event.room}
                        </p>
                        <p className="small mb-0">
                          {event.age_rating}
                        </p>
                        {event.paid_event === 1 && (
                          <p className="text-danger small mb-0">
                            <strong>Separately Ticketed Event</strong>
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Event Modal */}
      {selectedEvent && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedEvent.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{selectedEvent.description}</p>
            <p><strong>Time:</strong> {new Date(selectedEvent.full_start).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} - {new Date(selectedEvent.full_end).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}</p>
            {selectedEvent.age_rating === "18+" ? (
              <p className="text-danger">
                <strong>Age Restricted Event - Wristband Required</strong>
              </p>
            ) : null}
            <p><strong>Age Rating:</strong> {selectedEvent.age_rating}</p>
            <p><strong>Host:</strong> {selectedEvent.hosts}</p>
            <p><strong>Location:</strong> {selectedEvent.location} - {selectedEvent.room}</p>
            {selectedEvent.paid_event === 1 && (
              <>
                <p className="text-danger">
                  <strong>Separately Ticketed Event</strong>
                </p>
                <a href={selectedEvent.tickets_url} className="btn btn-primary" target="_blank" rel="noreferrer">Buy Tickets</a>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default Schedule;

